.tracking-detail {
  padding: 3rem 0;
}
#tracking {
  margin-bottom: 1rem;
}
[class*="tracking-status-"] {
  p {
    margin: 0;
    font-size: 1.1rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }
  padding: 1.6rem 0;
}
.tracking-list {
  border: 1px solid #e5e5e5;
}
.tracking-item {
  border-left: 1px solid #e5e5e5;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
  &:last-child {
    padding-bottom: 4rem;
  }
  .tracking-date {
    margin-bottom: 0.5rem;
    span {
      color: #888;
      font-size: 85%;
      padding-left: 0.4rem;
    }
  }
  .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;
    span {
      display: block;
      color: #888;
      font-size: 85%;
    }
  }
  .tracking-icon {
    line-height: 1.6rem;
    position: absolute;
    left: -0.8rem;
    width: 1.6rem;
    height: 1.6rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    background-color: #fff;
    // color: #fff;
  }
  .tracking-icon.status-inactive {
    color: #f68;
  }
  .tracking-icon.status-canceled {
    color: #f68;
  }
  .tracking-icon.status-deleted {
    color: #f68;
  }
  .tracking-icon.status-active {
    color: #4cbb87;
  }
  .tracking-icon.status-completed {
    color: #4cbb87;
  }
  .tracking-icon.status-intransit {
    color: #e5e5e5;
    border: 1px solid #e5e5e5;
    font-size: 0.6rem;
  }
}

@media (min-width: 992px) {
  .tracking-item {
    margin-left: 10rem;
    .tracking-date {
      position: absolute;
      left: -10rem;
      width: 7.5rem;
      text-align: right;
      span {
        display: block;
      }
    }
    .tracking-content {
      padding: 0;
      background-color: transparent;
    }
  }
}
